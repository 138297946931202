import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.exec.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import bigDecimal from "js-big-decimal";
import dayjs from "dayjs";
import debounce from "debounce";
import { mapGetters } from "vuex";
import { calculateCube, calculateWeight, WEBSITE, imageUrlApi } from "@/config";
import { GET_TRACKINGS } from "@/graphql/transport.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  name: "transport_list",
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "รายการพัสดุ"
    }]);
  },
  created: function created() {
    document.title = "\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23\u0E1E\u0E31\u0E2A\u0E14\u0E38 | ".concat(WEBSITE);
  },
  data: function data() {
    return {
      page: 1,
      pageSize: 10,
      transportList: {},
      loading: false,
      status: "",
      search: ""
    };
  },
  methods: {
    imageUrlApi: imageUrlApi,
    calculateCube: calculateCube,
    calculateWeight: calculateWeight,
    getStatusText: function getStatusText() {
      switch (this.status) {
        case "":
          return "ทั้งหมด";

        case "0":
          return "รอสินค้าเข้าโกดัง";

        case "10":
          return "สินค้าเข้าโกดังจีน";

        case "20":
          return "กำลังส่งมาโกดังปลายทาง";

        case "30":
          return "รอชำระเงิน";

        case "40":
          return "เตรียมส่ง";

        case "99":
          return "ส่งแล้ว";

        default:
          return "";
      }
    },
    debounceInput: debounce(function (e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    getStatusColor: function getStatusColor(status) {
      switch (status) {
        case -10:
          return "default";

        case 0:
          return "warning";

        case 1:
          return "primary";

        case 2:
          return "warning";

        case 40:
        case 99:
          return "success";

        default:
          return "primary";
      }
    },
    format_date: function format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY");
      }

      return "-";
    },
    format_number: function format_number(val) {
      var decimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      return bigDecimal.round(val, decimal);
    }
  },
  apollo: {
    transportList: {
      query: GET_TRACKINGS,
      variables: function variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search,
          status: this.status
        };
      }
    }
  },
  computed: _objectSpread({}, mapGetters(["layoutConfig", "getCurrentNoti"]))
};