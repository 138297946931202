import _taggedTemplateLiteral from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import gql from "graphql-tag";
export var GET_TRACKINGS = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query($page: Int!, $pageSize: Int!, $status: String, $search: String) {\n    transportList(\n      page: $page\n      pageSize: $pageSize\n      status: $status\n      search: $search\n    ) {\n      recordsData {\n        recordsFiltered\n      }\n      records {\n        _id\n        transportId\n        transportNumber\n        amount\n        ship_by\n        payshipping_id\n        payshipping_doc\n        file_tracking\n        user {\n          userId\n          username\n        }\n        in_order {\n          orderId\n          orderCode\n        }\n        userLevelPrice {\n          userLevelDetailName\n          userLevelDetailPriceKg\n          userLevelDetailPriceCube\n          userLevelDetailShipping\n        }\n        size {\n          weight\n          long\n          height\n          width\n        }\n        logisticsServicePrice {\n          _id\n          priceName\n          priceValue\n        }\n        trackingPrice {\n          priceName\n          priceValue\n        }\n        trackingWarehouse {\n          _id\n          name\n          warehouseKg\n          warehouseCube\n        }\n        lotId\n        no\n        timeline {\n          send_to_warehouse_china\n          send_out_china\n          send_to_warehouse_th\n          payshipping\n          sent_out_to_customer\n        }\n        remark\n        remarkForCustomer\n        remarkPurchaseOrder\n        guarantee\n        status\n        statusShow\n        created_at\n      }\n    }\n  }\n"])));
export var GET_TRACKING = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query($id: Int!) {\n    transport(id: $id) {\n      _id\n      transportId\n      transport_doc\n      transportNumber\n      amount\n      ship_by\n      payshipping_id\n      payshipping_doc\n      userId\n      lotId\n      status\n      file_tracking\n      serviceCube\n      serviceKg\n      userLevelPrice {\n        userLevelDetailName\n        userLevelDetailPriceKg\n        userLevelDetailPriceCube\n        userLevelDetailShipping\n      }\n      user {\n        userId\n        username\n      }\n      in_order {\n        orderId\n        orderCode\n      }\n      size {\n        weight\n        long\n        height\n        width\n      }\n      logisticsServicePrice {\n        _id\n        priceName\n        priceValue\n      }\n      trackingPrice {\n        priceName\n        priceValue\n      }\n      trackingWarehouse {\n        _id\n        name\n        warehouseKg\n        warehouseCube\n      }\n      timeline {\n        send_to_warehouse_china\n        send_out_china\n        send_to_warehouse_th\n        payshipping\n        sent_out_to_customer\n      }\n      remark\n      remarkForCustomer\n      guarantee\n      created_at\n    }\n  }\n"])));
export var GET_FIND_TRACKINGS = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query($page: Int!, $pageSize: Int!, $type: String, $search: String) {\n    transportList(\n      page: $page\n      pageSize: $pageSize\n      type: $type\n      search: $search\n    ) {\n      recordsData {\n        recordsFiltered\n      }\n      records {\n        _id\n        transportId\n        transportNumber\n        amount\n        ship_by\n        user {\n          userId\n          username\n        }\n        in_order {\n          orderId\n          orderCode\n        }\n        userLevelPrice {\n          userLevelDetailName\n          userLevelDetailPriceKg\n          userLevelDetailPriceCube\n          userLevelDetailShipping\n        }\n        size {\n          weight\n          long\n          height\n          width\n        }\n        logisticsServicePrice {\n          _id\n          priceName\n          priceValue\n        }\n        trackingPrice {\n          priceName\n          priceValue\n        }\n        lotId\n        no\n        timeline {\n          send_to_warehouse_china\n          send_out_china\n          send_to_warehouse_th\n          payshipping\n          sent_out_to_customer\n        }\n        remark\n        remarkForCustomer\n        guarantee\n        status\n        statusShow\n        created_at\n      }\n    }\n  }\n"])));
export var UPDATE_TRACKING = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation($input: TrackingInput) {\n    updateTransport(input: $input) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var CREATE_TRACKING = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation($input: TrackingInput) {\n    createTransport(input: $input) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var DELETE_TRACKING = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  mutation($id: Int) {\n    removeTransport(id: $id) {\n      isSuccess\n      message\n    }\n  }\n"])));